@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: none;
}

html {
  overflow-x: hidden;
  text-size-adjust: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

/* Override Chakra Styling  */
#clipboardIconBtn span {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  padding-right: 4px;
}

.builder__section-checkbox .chakra-checkbox__control {
  border-radius: 4px;
}

.builder__section-checkbox .chakra-checkbox__control[data-focus] {
  box-shadow: none;
  outline: none;
}

.chakra-checkbox__input[data-focus-visible-added]
  + .chakra-checkbox__control[data-focus] {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 60%);
}

/* section title hover edit icon */
.builder__accordion-item:hover .builder__section-title-edit-icon {
  display: flex;
}

.builder__accordion-item:hover
  .builder__section-title-edit-icon[data-is-editing='true'] {
  display: none;
}

.builder__section-title-form {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

div.chakra-alert {
  background: #111;
}
.chakra-alert__icon svg path {
  fill: #92faef;
}

.builder__email-preview-btn-star svg {
  width: 0.75rem;
  height: 0.75rem;
}

#previewNotesBtn svg {
  width: 25px;
  height: 25px;
}

#EditNotesBtn svg {
  width: 25px;
  height: 25px;
}

.hyperlink {
  color: #773ae7;
  text-decoration: underline;
}

@media screen and (min-width: 950px) {
  .builder__section-podcast-image-preview-container:hover
    .builder__section-modal-edit-icon-container {
    display: block;
  }
}
